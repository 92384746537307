<template>
	<div class="p-5 flex flex-col items-center">
		<img src="@/assets/NUBETECH.png" alt="" class="p-2 h-16" />
		<n-card :title="currentShop.name" hideOk hideCancel>
			<!-- pin  -->
			<div class="w-full flex justify-center space-x-5 pb-3">
				<p v-for="i in pin.length" :key="`input-${i}`" class="bg-primary rounded-full w-5 h-5 text-transparent"></p>
				<p v-for="j in 4 - pin.length" :key="`empty-${j}`" class="bg-white rounded-full w-5 h-5 text-transparent"></p>
			</div>
			<!-- NumberPad -->
			<n-number-pad @select="selectNumber"></n-number-pad>
		</n-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			pin: '',
		}
	},

	computed: {
		...mapGetters(['currentShop', 'currentAdjustment']),
	},

	methods: {
		selectNumber(number) {
			if (number === '<') return (this.pin = this.pin.slice(0, -1))
			if (number === 'C') return (this.pin = '')
			if (this.pin.length !== 4) this.pin += String(number)
			if (this.pin === '1234' && this.currentAdjustment.status === 'OPEN') return this.$router.push('/menu')
			else if (this.pin === '1234') return this.$store.dispatch('setError', { message: '訂單已完成,如有問題請聯絡吖Kit', status: true })
			else if (this.pin === '3721') return this.$router.push('/inventory')
		},
	},
}
</script>
